import React from 'react';
import PropTypes from 'prop-types';
import Slider from 'react-slick';

import FeaturedArticle from './FeaturedArticle/FeaturedArticle';

class FeaturedArticles extends React.Component {
    render() {
        const { featuredArticles, alternativeLayout } = this.props;
        const featuredArticleLength = featuredArticles.length;

        const sliderSettings = {
            arrows: false,
            slidesToShow: 1,
            dotsClass: 'featured-articles--mobile__slider-dots',
            adaptiveHight: true,
            dots: true,
            autoplay:
                typeof window !== 'undefined'
                    ? !window.DISABLE_ANIMATIONS
                    : true,
            autoplaySpeed: 5500
        };

        return (
            <React.Fragment>
                {alternativeLayout ? (
                    <React.Fragment>
                        <section
                            className={`featured-articles featured-articles--desktop featured-articles--${featuredArticleLength}${
                                alternativeLayout !== undefined
                                    ? '--alt-layout'
                                    : ''
                            }`}>
                            {featuredArticles.map((article, index) => (
                                <FeaturedArticle
                                    article={article}
                                    key={article.id}
                                />
                            ))}
                        </section>
                        <section
                            className={`featured-articles featured-articles--mobile featured-articles--${featuredArticleLength}${
                                alternativeLayout !== undefined
                                    ? '--alt-layout'
                                    : ''
                            }`}>
                            <Slider {...sliderSettings}>
                                {featuredArticles &&
                                    featuredArticles.length > 0 &&
                                    featuredArticles.map((article, index) => (
                                        <FeaturedArticle
                                            article={article}
                                            key={article.id}
                                        />
                                    ))}
                            </Slider>
                        </section>
                    </React.Fragment>
                ) : (
                    <section
                        className={`featured-articles featured-articles--${featuredArticleLength}`}>
                        {featuredArticles.map((article, index) => (
                            <FeaturedArticle
                                article={article}
                                key={article.id}
                            />
                        ))}
                    </section>
                )}
            </React.Fragment>
        );
    }
}

FeaturedArticles.propTypes = {
    featuredArticles: PropTypes.arrayOf(
        PropTypes.shape({
            articleTitle: PropTypes.string,
            articleHeaderImage: PropTypes.shape({
                title: PropTypes.string,
                file: PropTypes.shape({
                    url: PropTypes.string
                })
            })
        })
    ),
    alternativeLayout: PropTypes.bool
};

export default FeaturedArticles;
