import React from 'react';

import 'pretty-checkbox/dist/pretty-checkbox.min.css';

import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import './contentHub.css';

const ContentHubWrapper = ({ children }) => {
    return <>{children}</>;
};

export default ContentHubWrapper;
