/* eslint-disable jsx-a11y/anchor-has-content */

import React from 'react';
import PropTypes from 'prop-types';
import Link from 'gatsby-link';
import Img from 'gatsby-image';
import lodashGet from 'lodash/get';
import styles from './ArticleListArticle.module.scss';

import IwLink from '../../IwLink/IwLink';
import {
    getContenthubArticleFullSlug,
    getContenthubCategoryFullSlug
} from '../ContentHubHelper';

class ArticleListArticle extends React.Component {
    render() {
        const {
            articleTitle,
            articleHeaderImage,
            categoryTag,
            slug,
            subtitle
        } = this.props.article;
        const headerImage = lodashGet(articleHeaderImage, 'fluid');

        const category = lodashGet(categoryTag, 'categoryTag');
        let categorySlug = categoryTag.blog_sub_page_
            ? categoryTag.blog_sub_page_[0].slug
            : categoryTag.categoryLink;
        const categoryPageLink = getContenthubCategoryFullSlug(
            category,
            categorySlug
        );

        return (
            <div className="article-list__article">
                <IwLink
                    className={styles.container}
                    title={articleTitle}
                    to={getContenthubArticleFullSlug(category, slug)}>
                    <div className={styles.imageContainer}>
                        {headerImage && (
                            <Img fluid={{ ...headerImage, aspectRatio: 1 }} />
                        )}
                    </div>
                    <div className={styles.details}>
                        <h4 className={styles.title}>
                            <span className="iw-magic-underline">
                                {articleTitle}
                            </span>
                        </h4>
                        {subtitle && (
                            <p className={styles.excerpt}>
                                {subtitle.subtitle}
                            </p>
                        )}
                        {categoryTag && (
                            <Link
                                className={styles.category}
                                to={categoryPageLink}>
                                {category}
                            </Link>
                        )}
                    </div>
                </IwLink>
            </div>
        );
    }
}

ArticleListArticle.propTypes = {
    article: PropTypes.shape({
        articleTitle: PropTypes.string.isRequired,
        subtitle: PropTypes.shape({
            subtitle: PropTypes.string
        }),
        articleHeaderImage: PropTypes.shape({
            file: PropTypes.shape({
                url: PropTypes.string
            })
        }),
        categoryTag: PropTypes.shape({
            blog_sub_page_: PropTypes.arrayOf(
                PropTypes.shape({
                    slug: PropTypes.string
                })
            )
        })
    })
};

export default ArticleListArticle;
