/* eslint-disable jsx-a11y/anchor-has-content */

import React from 'react';
import PropTypes from 'prop-types';
import Img from 'gatsby-image';
import lodashGet from 'lodash/get';
import IwLink from '../../../../IwLink/IwLink.jsx';
import { getContenthubArticleFullSlug } from '../../../ContentHubHelper.js';

class FeaturedArticle extends React.Component {
    render() {
        const {
            articleTitle,
            subtitle,
            articleHeaderImage,
            slug,
            categoryTag
        } = this.props.article;

        const category = lodashGet(categoryTag, 'categoryTag');
        const link = getContenthubArticleFullSlug(category, slug);

        return (
            <div className="featured-articles__article">
                <IwLink
                    className="featured-articles__article__link"
                    to={link}
                    title={articleTitle}
                />
                <header className="featured-articles__article-header">
                    <h2 className="featured-articles__article-title">
                        <span className="iw-magic-underline">
                            {articleTitle}
                        </span>
                    </h2>
                    {subtitle && (
                        <h5 className="featured-articles__article-subTitle">
                            {subtitle.subtitle}
                        </h5>
                    )}
                </header>
                <figure className="featured-articles__article-figure">
                    {lodashGet(articleHeaderImage, 'fluid') && (
                        <Img
                            fluid={articleHeaderImage.fluid}
                            alt={articleTitle}
                        />
                    )}
                </figure>
            </div>
        );
    }
}

FeaturedArticle.propTypes = {
    article: PropTypes.shape({
        articleTitle: PropTypes.string,
        articleHeaderImage: PropTypes.shape({
            title: PropTypes.string,
            file: PropTypes.shape({
                url: PropTypes.string
            })
        })
    })
};

export default FeaturedArticle;
