import React from 'react';
import PropTypes from 'prop-types';

import InfiniteScroll from './InfiniteScroll';
import ArticleListArticle from './ArticleListArticle';
import styles from './ArticleList.module.scss';

class ArticleList extends React.Component {
    render() {
        const { articleList, paginationCta, heading } = this.props;

        return (
            <section className={styles.list}>
                {heading && <h2 className="title">{heading}</h2>}
                <InfiniteScroll loadMoreText={paginationCta}>
                    {articleList &&
                        articleList.map((article) => (
                            <ArticleListArticle
                                article={article}
                                key={article.id}
                            />
                        ))}
                </InfiniteScroll>
            </section>
        );
    }
}

/* eslint-disable */
ArticleList.propTypes = {
    heading: PropTypes.string,
    paginationCta: PropTypes.string,
    articleList: PropTypes.array
};
/* eslint-enable */

export default ArticleList;
